import React from 'react'
import { Link } from "react-router-dom";


const NowEnrolling = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <footer className="tf__footer_2">
          <div className="container">
            <div className="tf__footer_apply" style={{ background: 'url(./assets/images/AcademyBanner_3.jpg)' }}>
              <div className="tf__footer_apply_overlay">
                <a className="venobox" data-autoplay="true" data-vbtype="video" to="https://youtu.be/xsnCYCEbdr4">
                  <i className="fas fa-play" />
                </a>
                <h3> Enrolling Now  !</h3>
                <p>Limited Spots available for the 2025-2026 School Year.</p>
                <a className="apply_btn" href='https://docs.google.com/forms/d/e/1FAIpQLSfQg5hfLSH2gL_3ZDRoAFp1zL3mY8mBfkCM_FD37ZxXZdH9sg/viewform' target="_blank">Enroll Now</a>
                {/* <a className="apply_btn" href='https://us.mohid.co/nc/durham/jamaat/masjid/online/registration/index/ZXF1RytHdDhWMUsvU0JxSmZXSmY0dz09' target="_blank">Enroll Now</a> */}
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="tf__footer mt-lg-1" style={{ background: 'url(./assets/images/Al-Qalam_6.jpg)' }}>
            <div className="tf__footer_overlay pt_225">
              <div className="container">
                <div className="tf__footer_2_content_area">
                  <div className="row justify-content-between">
                    <div className="col-xl-3 col-md-7 col-lg-3">
                      <div className="tf__footer_logo_area">
                        <Link className="navbar-brand" to="/">
                          <img src="./assets/images/JIAR-removebg-preview.png" alt="Eduor" className="img-fluid" style={{ width: "100%" }} />
                        </Link>
                        <p>Al Qalam Triangle Academy is affiliated to JIAR!</p>
                        {/* <p>Grounded in the teachings of Islam, we strive to create a nurturing environment where children develop a profound love for Allah, embody Islamic values.</p> */}
                        <ul className="d-flex flex-wrap">
                          <li><a to="#" style={{ color: "white" }}><i className="fab fa-facebook-f" /></a></li>
                          <li><a to="#" style={{ color: "white" }}><i className="fab fa-linkedin-in" /></a></li>
                          <li><a to="#" style={{ color: "white" }}><i className="fab fa-twitter" /></a></li>
                          <li><a to="#" style={{ color: "white" }}><i className="fab fa fa-youtube-play" /></a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-2 col-md-5 col-lg-3">
                      <div className="tf__footer_content">
                        <h3>Quick Links</h3>
                        <ul>
                          <li><Link to="/" style={{ color: "white" }}>Home</Link></li>
                          <li><Link to="/about-us" style={{ color: "white" }}>About Us</Link></li>
                          <li><Link to="/programs" style={{ color: "white" }}>
                            Programs</Link></li>
                          <li><Link to="/faq" style={{ color: "white" }}>FAQ</Link></li>
                          <li><Link to="/contact-us" style={{ color: "white" }}>Contact Us</Link></li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-7 col-lg-3">
                      <div className="tf__footer_content">
                        <h3>Our Contacts</h3>
                        <a href='https://maps.app.goo.gl/LVJyrMATMz7tpnHu9' target="_blank"> <p>Address: 3034 Fayetteville St, Durham, NC 27707</p></a>
                        {/* <p>Adress #2: 5104-08 Revere Rd Durham, NC 27713</p> */}
                        <p>
                          <span>
                            <a style={{ color: "white" }} href="tel:+1 919-321-8485"> Phone: +1 919-321-8485 </a>
                          </span>
                        </p>

                        {/* <p>
                          <span>
                          
                            <a style={{ color: "white" }} href="https://chat.whatsapp.com/IWKFYcPcLkM3v0OKA4J7jr" target="_blank"> WhatsApp Join Group </a>
                          </span>
                        </p> */}
                        <ul className="d-flex flex-wrap ">
                          <li><a href="https://chat.whatsapp.com/IWKFYcPcLkM3v0OKA4J7jr" target="_blank" style={{ color: "white" }}><i className="fab fa-whatsapp" /> WhatsApp-Join Group</a></li>
                        </ul>
                        <p>
                          <span>
                            <a style={{ color: "white" }} href="mailto:admin@aqta.org">Email: admin@aqta.org</a>
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-xl-3 col-md-5 col-lg-3">
                      <div className="tf__footer_content">
                        <h3>News Letter</h3>
                        <form>
                          <input type="text" placeholder="Your Email" />
                          <button>send</button>
                        </form>
                      </div>
                    </div> */}
                    <div className="col-12">
                      <div className="tf__copyright">
                        <p>2024 © Al-Qalam Triangle Academy | <a href='https://astuteitservices.com/' target='_blank' style={{ color: "white" }}> Designed by Astute IT Services</a> </p>
                        {/* <ul className="d-flex flex-wrap">
                          <li><a to="#">Privacy policy</a></li>
                          <li><a to="#">About</a></li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="tf__scroll_btn_2 tf__scroll_btn" style={{ background: "#1774ba" }}><i className="far fa-long-arrow-up" /></div>
      </div>

    </>
  )
}

export default NowEnrolling