import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import ContactUs from "./pages/contact-us/ContactUs";
import AboutUs from "./pages/about/AboutUs";
import Faq from "./pages/faq/Faq";
import Programs from "./pages/programs/Programs";
import OurCurriculum from "./pages/about/OurCurriculum";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/programs" element={<Programs />} />
            {/* <Route path="/our-curriculum" element={<OurCurriculum />} /> */}
            <Route path="/contact-us" element={<ContactUs />} />
           
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
